import { Helmet } from 'react-helmet'

import * as React from 'react'
import Layout from '../components/Layout'

import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import FAQs from '../components/FAQs'

import { Box, Flex } from 'grid-styled'
import CTA from '../components/CTA'
import GalleryContainer from '../components/GalleryContainer'
import GalleryImage from '../components/GalleryImage'
import {
  HomeHeader,
  FormContainer,
  AlternatingContent,
  AboutContact,
  VideoContainer,
  info,
} from '.'
import Form from '../components/Form'
import { Text, GallerySection, Content } from '../components/styles'
import Button from '../components/Button'
import ContentCard from '../components/ContentCard'
import Hero from '../components/Hero'
import { Video } from '../components/procedures'

export default ({ data }) => (
  <Layout header="light">
    <Helmet>
      <title>Which Wing - Queensland Sports Aviators</title>
      <meta
        name="description"
        content="If you have an urge to fly but are unsure of where to begin, take a look
        at some of our differing aviation options below."
      />
    </Helmet>

    <HomeHeader slant={false} height="60vh">
      <figure>
        <Img fluid={data.wing.childImageSharp.fluid} />
      </figure>
      <div>
        <h2>Which wing?</h2>
        <Text size="large">
          Want to learn to fly, start by exploring our different aviation
          experiences.
        </Text>
      </div>
    </HomeHeader>

    <Content style={{ paddingTop: 0 }}>
      <h1>Explore our Aviation Experiences</h1>
      <h4>
        We're often asked which wing should I learn to fly or have a tandem on?
      </h4>

      <p>
        If you have an urge to fly but are unsure of where to begin, take a look
        at some of our aviation options below. Each experience brings with it a
        unique sense of freedom and adrenaline, explore the options and start
        with the wing that calls to you.
      </p>
      <p>
        Ask us today about one of our local flight experiences. Join a local
        club, book your first tandem adventure or make the commitment and learn
        to fly on your own wing.
      </p>

      <Link to="/contact-us/">
        <Button inverted={true}>Contact us today!</Button>
      </Link>
    </Content>

    <AlternatingContent>
      <h2>
        Our <i>Aviation</i> Experiences
      </h2>
      <ContentCard
        image={data.paragliding.childImageSharp}
        title="Paragliding"
        text="A Paraglider pilot sits in a harness below a fabric wing that holds its shape through suspension lines and air pressure. Paragliders are hand controlled, and for a learner, it’s probably the easiest to pick up. Simple to set up and lightweight, a paraglider is carried in a back pack. Along with Hang Gliding, it’s the cheapest form of aviation."
        buttons={[
          { text: 'find an instructor', to: '/flight-school-instructors/' },
        ]}
      />
      <ContentCard
        image={data.hanggliding.childImageSharp}
        title="Hang Gliding"
        text="A Hang Glider pilot is suspended in a harness below a rigid wing which is usually an aluminium frame covered in a synthetic sail cloth. They are a little more substantial in structure than a Paraglider, but have better glide performance and can fly in stronger winds."
        buttons={[
          { text: 'find an instructor', to: '/flight-school-instructors/' },
        ]}
      />
      <ContentCard
        image={data.microlighting.childImageSharp}
        title="Microlighting"
        text="Microlight trike pilots sit in a propeller-driven tricycle pod suspended below a fabric flex-wing. Like Hang Gliders they are weight shift controlled. However, Microlights are more versatile allowing you to fly higher, faster and in more varied weather. Running costs are slightly higher, and they need a trailer for transport unless your aircraft is stored at an airfield, which is commonly the case."
        buttons={[
          { text: 'find an instructor', to: '/flight-school-instructors/' },
        ]}
      />
      <ContentCard
        image={data.powered.childImageSharp}
        title="Powered Paragliding"
        text="Powered Paraglider or Paramotor pilots wear a motor on their back which provides enough thrust to take off with a Paraglider, specifically designed for motored operations. Best suited to low wind conditions, making them easier to launch, Paramotoring lets you safely fly low and slow. There’s also less injury risk during take-off or landing. Easy to transport and cheaper than microlights, they are a great option for powered pure flight."
        buttons={[
          { text: 'find an instructor', to: '/flight-school-instructors/' },
        ]}
      />
    </AlternatingContent>

    <Hero bgImage={data.blurredHero.childImageSharp} height="550px">
      <h3>Want to find our more?</h3>
      <p>
        Complete the enquiry form to contact us today and find out how we can
        help.
      </p>
      <Flex>
        <Button color={'#fff'} small={true} outline={true}>
          Contact Us
        </Button>
      </Flex>
    </Hero>
  </Layout>
)

export const query = graphql`
  query CarportsQuery {
    paragliding: file(relativePath: { eq: "paragliding.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hanggliding: file(relativePath: { eq: "hang-gliding.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    microlighting: file(relativePath: { eq: "microlighting.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    powered: file(relativePath: { eq: "powered.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wing: file(relativePath: { eq: "which-wing.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    offField: file(relativePath: { eq: "gallery/carports/gable/cover.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    whyUs: file(relativePath: { eq: "why-us-jp.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    patiosBrisbane: file(
      relativePath: { eq: "gallery/carports/skillion/cover.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    training: file(relativePath: { eq: "gallery/carports/flyover/cover.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    accessories: file(relativePath: { eq: "roofing-brisbane.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blurredHero: file(relativePath: { eq: "bg-contact.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
